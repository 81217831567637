import dayjs from 'dayjs';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { PATTERNS } from './consts';
/**
 * This function makes sure the string being typed into the control matches the parameter
 * @param password This is the original password, that we're checking against the confirm password
 */
export function checkMatchingPassword(password: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const confPw = control.value;
    const passwordsMatch = (confPw === password);
    return passwordsMatch ? { 'mismatchedPw': 'Please make sure the passwords match' } : null;
  };
}

export const passwordMatcher = (control: AbstractControl): { [key: string]: boolean } => {
  const oldPassword = control.get('oldPassword');
  const newPassword = control.get('newPassword');
  const confirmPw = control.get('confirmPassword');
  if (!newPassword || !confirmPw) {
    return null;
  }


  const newPassValue = newPassword.value;
  let testMatch = false;
  if (newPassValue && newPassValue.length > 0 && typeof newPassValue === 'string') {
     testMatch = PATTERNS.Password.test(newPassValue);
  }
  if (oldPassword?.value === newPassword.value) {
    return { duplicated: true }
  } else if (newPassword.value === confirmPw.value && !testMatch) {
    return { noValidate: true };
  } else if (newPassword.value && confirmPw.value && newPassword.value !== confirmPw.value) {
    return { nomatch: true }
  } else {
    return null;
  }
};

export const durationChecker = (control: AbstractControl): { [key: string]: any } | null => {
  const startDate = dayjs(control.get('selectedStartDate').value).format('MM/DD/YYYY');
  const endDate = dayjs(control.get('selectedEndDate').value).format('MM/DD/YYYY');
  const startTime = control.get('selectedStartTime').value;
  const endTime = control.get('selectedEndTime').value;
  const startDateTime = dayjs(`${startDate} ${startTime}`, 'MM/DD/YYYY HH:mma');
  const endDateTime = dayjs(`${endDate} ${endTime}`, 'MM/DD/YYYY HH:mma');
  if (startDateTime && endDateTime) {
    const duration = dayjs
      .duration(endDateTime.diff(startDateTime))
      .asSeconds();
    return +duration > 0 ? null : { negativeDuration: { duration } };
  }
  return null;
}


// export function passwordMatcher(g: FormGroup) {
//    return g.get('password').value === g.get('passwordConfirm').value
//       ? null : {'mismatch': true};
// }

export const emailListValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    return (control.value ?? []).every(s => PATTERNS.Email.test(s))
      ? null
      : { email: true };
  }
}
